<template>
  <v-row class="justify-center">
    <ca-page-header :color="null" :size="56">
      <template #title>
        {{ t("bonus_program") }} <span class="amber--text text--darken-2">{{ t("gold_partners") }}</span>
      </template>

      <template #icon>
        <v-img :src="medalImg" />
      </template>

      <span v-html="t('page_subtitle')"></span>
    </ca-page-header>

    <v-col :sm="12" :md="10" :lg="9">
      <v-card>
        <v-card-title>{{ t("how_to_get_gold_status") }}</v-card-title>

        <v-card-subtitle v-html="t('how_to_get_gold_status_subtitle')"></v-card-subtitle>

        <v-card-text>
          <i18n path="pages.gold.how_to_get_gold_status_line">
            <template #link>
              <router-link :to="{ name: 'earnings', params: { app: 'fd1' } }"> «{{ t("earnings") }}» </router-link>
            </template>

            <template #efficiency>
              <strong>{{ t("efficiency_is_above", { value: "0" }) }}</strong>
            </template>

            <template #gold_partner>
              <strong>{{ t("golden_partner") }}</strong>
            </template>
          </i18n>
        </v-card-text>

        <v-card-title>{{ t("how_to_increase_extra_bonus_amount") }}</v-card-title>

        <v-card-subtitle>
          {{ t("how_to_increase_extra_bonus_amount_subtitle") }}
        </v-card-subtitle>

        <v-card-text>
          <div class="d-flex">
            <v-icon color="amber">mdi-plus</v-icon>
            <v-icon color="amber" class="mr-2">mdi mdi-alpha-i-circle</v-icon>
            <span>
              {{ t("how_to_increase_extra_bonus_amount_line_1") }}
            </span>
          </div>

          <div class="d-flex mt-2">
            <v-icon color="amber">mdi-plus</v-icon>
            <v-icon color="amber" class="mr-2">mdi mdi-alpha-i-circle</v-icon>
            <span>
              {{ t("how_to_increase_extra_bonus_amount_line_2") }}
            </span>
          </div>

          <div class="d-flex mt-2">
            <v-icon color="blue">mdi-minus</v-icon>
            <v-icon color="blue" class="mr-2">mdi mdi-alpha-i-circle</v-icon>
            <span>
              {{ t("how_to_increase_extra_bonus_amount_line_3") }}
            </span>
          </div>
        </v-card-text>

        <v-card-title>
          {{ t("comparative_table_of_partners_statuses") }}
        </v-card-title>

        <v-card-subtitle>
          {{ t("comparative_table_of_partners_statuses_subtitle") }}
        </v-card-subtitle>

        <v-card-text>
          <div
            class="v-data-table theme--light v-data-table elevation-2 mt-2 mb-2 v-data-table--dense"
            style="max-width: 600px;"
          >
            <div class="v-data-table__wrapper">
              <table id="tab3">
                <thead class="amber lighten-3">
                  <tr>
                    <th
                      v-for="header in table.headers"
                      class="py-2 text-center font-weight-bold"
                      v-html="header.text"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in table.items" :class="[index % 2 ? '' : 'yellow']" class="lighten-5">
                    <td class="py-3 text-left font-weight-bold">{{ item.status }}</td>
                    <td class="text-center">{{ item.efficiency }}</td>
                    <td class="text-center">{{ item.coins }}</td>
                    <td class="text-center">{{ item.percent_of_earnings }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </v-card-text>

        <v-card-text>
          <div class="caption" v-html="t('comparative_table_of_partners_statuses_hint_1')"></div>

          <div class="caption mt-2">
            {{ t("comparative_table_of_partners_statuses_hint_2") }}
          </div>

          <div class="caption mt-2">
            {{ t("comparative_table_of_partners_statuses_hint_3") }}
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import MedalImg from "@/assets/images/medal.svg";
import CaPageHeader from "../../components/PageHeader";

export default {
  metaInfo() {
    return {
      title: this.$t("titles.gold")
    };
  },

  components: { CaPageHeader },

  computed: {
    medalImg() {
      return MedalImg;
    },

    table() {
      return {
        headers: [
          { text: this.t("status") },
          { text: this.t("efficiency") },
          { text: this.t("coins") },
          { text: this.t("percent_of_earnings") }
        ],
        items: [
          {
            status: "Standart",
            efficiency: "<0",
            coins: "0",
            percent_of_earnings: "+0%"
          },
          {
            status: "Gold",
            efficiency: ">0",
            coins: "0",
            percent_of_earnings: "+0%"
          },
          {
            status: "Gold Poster",
            efficiency: ">0",
            coins: "10",
            percent_of_earnings: "+5%"
          },
          {
            status: "Gold Master",
            efficiency: ">0",
            coins: "25",
            percent_of_earnings: "+10%"
          },
          {
            status: "Gold Admin",
            efficiency: ">0",
            coins: "50",
            percent_of_earnings: "+15%"
          },
          {
            status: "Gold Hero",
            efficiency: ">0",
            coins: "80",
            percent_of_earnings: "+20%"
          },
          {
            status: "Gold Magnate",
            efficiency: ">0",
            coins: "120",
            percent_of_earnings: "+25%"
          }
        ]
      };
    }
  },

  methods: {
    t(key, params) {
      return this.$t("pages.gold." + key, params);
    }
  }
};
</script>
